import React from "react";
import {Helmet} from "react-helmet";
import {graphql, Link} from "gatsby";
import {GatsbyImage as Img, getImage, StaticImage, withArtDirection} from "gatsby-plugin-image";
import classNames from "classnames";

import {MOBILE_BREAKPOINT, useIsMobile} from "../util/responsiveness";
import Egg from "../components/common/Egg";
import ButtonLink from "../components/common/ButtonLink";
import HomeResearchAndDevelopmentLink from "../components/home/HomeResearchAndDevelopmentLink";
import HomeProductLink from "../components/home/HomeProductLink";
import H1WithLink from "../components/common/H1WithLink";
import NewsLink from "../components/news/NewsLink";
import Marquee from "../components/common/Marquee";

import * as classes from "./index.module.scss";

export default function IndexPage({data}) {

    const isMobile = useIsMobile();

    const newsEdges = data["allNews"]["edges"];

    return (
        <>
            <Helmet titleTemplate="%s">
                <title>RusCell – производственная медицинская компания</title>
                <meta name="description" content="«РУССЭЛЛ» — российский производитель транспортных сред и тест-систем для онкодиагностики." />
            </Helmet>
            <section className={classes.hero}>
                <div className={classes.heroContent}>
                    <h1>Производст­вен­ная медицинская компания</h1>
                    <p>Мы создаем инновации<br />в области экспресс-диагностики<br />злокачественных новообразований</p>
                    <ButtonLink
                        className={classes.heroLink}
                        to="/about"
                        variant="FILLED"
                        color="ACCENT-2">Узнать о нас больше</ButtonLink>
                </div>
                <div className={classes.heroEggContainer}>
                    <Egg className={classes.heroEgg}>
                        <Img
                            className={classes.heroImage}
                            image={withArtDirection(getImage(data["heroImageFile"]), [{
                                media: `(max-width: ${MOBILE_BREAKPOINT - 1}px)`,
                                image: getImage(data["heroMobileImageFile"])
                            }])}
                            alt="Производство медицинских изделий, транспортных сред" />
                    </Egg>
                </div>
            </section>
            <section>
                <h1>Наши продукты</h1>
                <div className={classes.productLinksContainer}>
                    <HomeProductLink
                        className={classes.productLink}
                        imageClassName={classes.productLinkBiochipImage}
                        title="Биочип"
                        description="Тест-система для ИЦХ исследования белков тканей человека"
                        slug="biochip"
                        image={withArtDirection(getImage(data["productLinkBiochipImageFile"]), [{
                            media: `(max-width: ${MOBILE_BREAKPOINT - 1}px)`,
                            image: getImage(data["productLinkBiochipMobileImageFile"])
                        }])}
                        imageAlt="Тест-система для диагностики рака" />
                    <HomeProductLink
                        className={classes.productLink}
                        imageClassName={classes.productLinkTps1Image}
                        title="ТПС-1"
                        description={<>Транспортная питательная <br />среда</>}
                        slug="tps-1"
                        image={withArtDirection(getImage(data["productLinkTps1ImageFile"]), [{
                            media: `(max-width: ${MOBILE_BREAKPOINT - 1}px)`,
                            image: getImage(data["productLinkTps1MobileImageFile"])
                        }])}
                        imageAlt="Транспортные среды для цитологического материала" />
                </div>
                <HomeResearchAndDevelopmentLink />
            </section>
            {!!newsEdges?.length &&
                <section className={classes.newsContainer}>
                    <H1WithLink
                        linkText={!isMobile ? "Посмотреть все новости" : "Ко всем новостям"}
                        linkTo="/news">Новости</H1WithLink>
                    <Marquee>
                        {newsEdges?.map(({node}) =>
                            <NewsLink
                                key={node.id}
                                className={classes.newsLink}
                                {...node} />)}
                        <Link
                            className={classNames(classes.newsLink, classes.newsMoreLink)}
                            to="/news">
                            <span className={classes.newsMoreLinkContent}>Еще новости</span>
                            <StaticImage
                                className={classes.newsMoreLinkImage}
                                src="img/news-more-link.jpg"
                                alt=""
                                layout="fixed"
                                width={365}
                                height={205} />
                        </Link>
                    </Marquee>
                </section>}
        </>
    );

}

export const pageQuery = graphql`
    query {
        heroImageFile: file(relativePath: {eq: "hero.jpg"}) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, width: 608, quality: 95)
            }
        }
        heroMobileImageFile: file(relativePath: {eq: "hero-mobile.jpg"}) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, width: 464, quality: 95)
            }
        }
        productLinkBiochipImageFile: file(relativePath: {eq: "biochip.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, width: 424, quality: 95, placeholder: NONE)
            }
        }
        productLinkBiochipMobileImageFile: file(relativePath: {eq: "biochip-mobile.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, width: 260, quality: 95, placeholder: NONE)
            }
        }
        productLinkTps1ImageFile: file(relativePath: {eq: "tps-1.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, width: 183, quality: 95, placeholder: NONE)
            }
        }
        productLinkTps1MobileImageFile: file(relativePath: {eq: "tps-1-mobile.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, width: 280, quality: 95, placeholder: NONE)
            }
        }
        allNews(
            sort: {fields: [timestamp], order: DESC}
            limit: 10
        ) {
            edges {
                node {
                    ...NewsLink
                }
            }
        }
    }
`;
