// extracted by mini-css-extract-plugin
export var hero = "index-module--hero--igj2E";
export var heroContent = "index-module--hero-content--1Nypr";
export var heroLink = "index-module--hero-link--M3IB-";
export var heroEggContainer = "index-module--hero-egg-container--rQXrO";
export var heroEgg = "index-module--hero-egg--1ld9R";
export var heroImage = "index-module--hero-image--9cLNF";
export var productLinksContainer = "index-module--product-links-container--DS7X3";
export var productLink = "index-module--product-link--ywEPB";
export var productLinkBiochipImage = "index-module--product-link-biochip-image--pizAJ";
export var productLinkTps1Image = "index-module--product-link-tps-1-image--Q5Gsz";
export var newsContainer = "index-module--news-container---jYMi";
export var newsLink = "index-module--news-link--8DEQK";
export var newsMoreLink = "index-module--news-more-link--WS6Vy";
export var newsMoreLinkContent = "index-module--news-more-link-content--jZupH";
export var newsMoreLinkImage = "index-module--news-more-link-image--LEEEC";