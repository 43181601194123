import React from "react";
import PropTypes from "prop-types";
import {GatsbyImage as Img} from "gatsby-plugin-image";
import classNames from "classnames";

import Card from "../common/Card";
import HomeProductLinkContent from "./HomeProductLinkContent";

import * as classes from "./HomeProductLink.module.scss";

export default function HomeProductLink({className, imageClassName, title, description, slug, image, imageAlt}) {
    return (
        <Card className={classNames(classes.container, className)}>
            <HomeProductLinkContent
                className={classes.content}
                heading={title}
                linkTo={`/products/${slug}`}
                linkText="Подробнее">{description}</HomeProductLinkContent>
            <Img
                className={classNames(classes.image, imageClassName)}
                image={image}
                alt={imageAlt} />
        </Card>
    );
}

HomeProductLink.propTypes = {
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    slug: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired
};
