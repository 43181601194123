import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ChevronLink from "./ChevronLink";

import * as classes from "./H1WithLink.module.scss";

export default function H1WithLink({className, linkText, linkTo, children}) {
    return (
        <div className={classNames(classes.container, className)}>
            <h1>{children}</h1>
            <ChevronLink className={classes.link} to={linkTo}>{linkText}</ChevronLink>
        </div>
    );
}

H1WithLink.propTypes = {
    className: PropTypes.string,
    linkText: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired
};
