import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";

import NewsLinkTemplate from "../../templates/news/NewsLinkTemplate";

export default function NewsLink({className, slug, title, date, timestamp, image, imageAlt}) {
    return (
        <NewsLinkTemplate
            className={className}
            slug={slug}
            title={title}
            date={date}
            timestamp={timestamp}
            image={image}
            imageAlt={imageAlt} />
    );
}

NewsLink.propTypes = {
    className: PropTypes.string,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    imageAlt: PropTypes.string.isRequired
};

export const query = graphql`
    fragment NewsLink on News {
        id
        slug
        title
        date: timestamp(formatString: "DD MMMM YYYY", locale: "ru")
        timestamp
        image {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 365, height: 205)
            }            
        }
        imageAlt
    }
`;
