import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ButtonLink from "../common/ButtonLink";

import * as classes from "./HomeProductLinkContent.module.scss";

export default function HomeProductLinkContent({className, heading, linkText, linkTo, children}) {
    return (
        <div className={classNames(classes.container, className)}>
            <h2>{heading}</h2>
            <p>{children}</p>
            <ButtonLink to={linkTo}>{linkText}</ButtonLink>
        </div>
    );
}

HomeProductLinkContent.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired
};
