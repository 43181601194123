import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Card from "../common/Card";
import HomeProductLinkContent from "./HomeProductLinkContent";

import * as classes from "./HomeResearchAndDevelopmentLink.module.scss";

export default function HomeResearchAndDevelopmentLink({className}) {
    return (
        <Card className={classNames(classes.container, className)}>
            <HomeProductLinkContent
                className={classes.content}
                heading="Пайплайн разработок"
                linkTo="/research-and-development"
                linkText="Перейти к разработкам">
                Скоро наш портфель продуктов расширится. Посмотрите, над чем сейчас трудится R&D департамент
            </HomeProductLinkContent>
            <ul className={classes.graphic}>
                <li><span>Клинические исследования</span></li>
                <li><span>Регистрация</span></li>
                <li><span>Коммерциализация</span></li>
            </ul>
        </Card>
    );
}

HomeResearchAndDevelopmentLink.propTypes = {
    className: PropTypes.string
};
