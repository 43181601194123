import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import classNames from "classnames";

import * as classes from "./NewsLinkTemplate.module.scss";
import PreviewCompatibleImage from "../../components/helpers/PreviewCompatibleImage";

export default function NewsLinkTemplate({className, slug, title, date, timestamp, image, imageAlt}) {

    const children = (
        <>
            <PreviewCompatibleImage
                className={classes.image}
                image={image}
                alt={imageAlt} />
            {title}{" "}
            <time className={classes.date} dateTime={timestamp}>{date}</time>
        </>
    );

    return slug ? (
        <Link
            className={classNames(classes.container, className)}
            to={`/news/${slug}`}>{children}</Link>
    ) : (
        <div className={classNames(classes.container, className)}>{children}</div>
    );

}

NewsLinkTemplate.propTypes = {
    className: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    imageAlt: PropTypes.string.isRequired
};
